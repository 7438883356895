import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import { Avatars } from "images/Avatars";
import Cookies from "universal-cookie";
import GameIcon from "../../images/Game";
import SDKIcon from "../../images/SDKIcon";
import UserIcon from "../../images/UserIcon";
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Collapse from "@mui/material/Collapse";
import Coupon from "../../images/Coupon";
import LogoutIcon from "../../images/LogoutIcon";
import SettingIcon from "../../images/settingIcon";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StorePackIcon from "../../images/StorePackIcon";
import AnalyticsIcon from "../../images/AnalyticsIcon";
import PublisherIcon from "../../images/PublisherIcon";
// import Logo from "../../assets/images/newLogoMGP.png";
import Logo from "../../assets/images/MGP3.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import WithdrawalIcon from "../../images/WithdrawalIcon";
import CommissionIcon from "../../images/CommissionIcon";
import ListItemButton from "@mui/material/ListItemButton";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HistoryIcon from "@mui/icons-material/History";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import RevenueIcon from "../../images/RevenueIcon";
import { agentDetails, hideActionFunc, userDetails } from "../../utils";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useDispatch, useSelector } from "react-redux";
import { getFlagConfig } from "../../Redux/settings/action";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { getPermissionsKey } from "../../Redux/AdminUser/action";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import BotIcon from "images/BotIcon";
import { Box, Typography } from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function LeftContent() {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const settingFlag = useSelector((state) => state?.settingReducer?.flagList);
  const navigate = useNavigate();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [permotions, setPermotions] = useState({});

  const [menusList, setMenuList] = useState([
    // Admin Users menu
    {
      icon: <GroupAddIcon />,
      label: "Admin Users",
      src: "admin-users",
      value: "admin",
      hasMoreMenu: false,
    },
    // Users menu with expandable submenus
    {
      icon: <GroupIcon />,
      label: "Users",
      hasMoreMenu: true,
      isExpanded: false,
      id: "userId",
      value: "user",
      expandArray: [
        {
          icon: "",
          label: "All Users",
          src: "Users",
        },
        {
          icon: "",
          label: "Inactive Users",
          src: "inactive-users",
        },
        // {
        //   icon: "",
        //   label: "Users Blocked",
        //   src: "block-user",
        // },
        // {
        //   icon: "",
        //   label: "Users Reported",
        //   src: "user-reported",
        // },
        {
          icon: "",
          label: "Users KYC",
          src: "user-kyc",
        },

        {
          icon: "",
          label: settingFlag?.isMobileNumberUpdateRequest
            ? "Users Mobile Number Request"
            : "Users Mobile Number Request",
          src: "user/update-mobile-number",
        },
        // {
        //     icon: '',
        //     label: "Users Withdrawal Requests",
        //     src: "user-withdrawal-requests",
        // },
      ],
    },
    // Games menu with expandable submenus
    {
      icon: <GameIcon />,
      label: "Games",
      hasMoreMenu: true,
      isExpanded: false,
      id: "gameId",
      value: "game",
      expandArray: [
        {
          icon: "",
          label: "All Games",
          src: "games/all-games",
        },
        {
          icon: "",
          label: "Approved Games",
          src: "games/approved-games",
        },
        {
          icon: "",
          label: "Archive Games",
          src: "games/archive-games",
        },
        {
          icon: "",
          label: "Pending Games",
          src: "games/pending-games",
        },
        {
          icon: "",
          label: "Rejected Games",
          src: "games/rejected-games",
        },
        hideActionFunc("game") && {
          icon: "",
          label: "Add New Game",
          src: "games/add-game",
        },
        // {
        //     icon: '',
        //     label: "Game History",
        //     src: "game/game-history",
        // },
      ],
    },
    // {
    //   icon: <SportsEsportsIcon />,
    //   label: "Tournament",
    //   hasMoreMenu: true,
    //   isExpanded: false,
    //   id: "tournamentId",
    //   value: "gameTournament",
    //   expandArray: [
    //     {
    //       icon: "",
    //       label: "Create Tournament",
    //       src: "add-tournament",
    //     },
    //     {
    //       icon: "",
    //       label: "All Tournament",
    //       src: "all-tournament",
    //     },
    //     {
    //       icon: "",
    //       label: "Tournament Documents",
    //       src: "tournament-documents",
    //     },
    //   ],
    // },
    // Analytics menu with expandable submenus
    {
      icon: <AnalyticsIcon />,
      label: "Analytics   ",
      hasMoreMenu: true,
      isExpanded: false,
      id: "analytics",
      value: "analytics",
      expandArray: [
        {
          icon: "",
          label: "Analytics Report",
          src: "analytics-report",
        },
        {
          icon: "",
          label: "Analytics Overview Report",
          src: "analytics-overview-report",
        },
        {
          icon: "",
          label: "Application Install & Uninstall",
          src: "analytics-application-install",
        },
        {
          icon: "",
          label: "Daily Report",
          src: "daily-report",
        },
      ],
    },
    {
      icon: <BotIcon />,
      label: "Bot   ",
      hasMoreMenu: true,
      isExpanded: false,
      id: "bot",
      value: "bot",
      expandArray: [
        {
          icon: "",
          label: "Bot",
          src: "bot",
        },
        {
          icon: "",
          label: "Bot Config",
          src: "bot-config",
        },
        {
          icon: "",
          label: "Bot Report",
          src: "bot-report",
        },
      ],
    },
    // Popular Games menu
    {
      icon: <GameIcon />,
      label: "Popular Games",
      src: "popular-games",
      value: "popularGame",
      hasMoreMenu: false,
    },
    // Bonus menu with expandable submenus
    {
      icon: <Coupon />,
      label: "Bonus",
      hasMoreMenu: true,
      isExpanded: false,
      id: "bonusId",
      value: "bonus",
      expandArray: [
        {
          icon: "",
          label: "Daily Bonus",
          src: "daily-wheel-bonus",
        },
        {
          icon: "",
          label: "Refer & Earn",
          src: "refer-and-earn",
        },
        {
          icon: "",
          label: "Coupon Code",
          src: "coupon-code",
        },
        // {
        //     icon: '',
        //     label: "Leaderboard",
        //     src: "leaderboard",
        // },
        // {
        //     icon: '',
        //     label:"Offer",
        //     src: "offer",
        // },
      ],
    },
    // Revenue menu with expandable submenus
    {
      icon: <RevenueIcon />,
      label: "Revenue",
      hasMoreMenu: true,
      isExpanded: false,
      id: "revenue",
      value: "revenue",
      expandArray: [
        {
          icon: "",
          label: "Game Revenue",
          src: "revenue/game-wise-revenue",
        },
        // {
        //     icon: '',
        //     label: "Overall Revenue",
        //     src: "revenue/overall-revenue",
        // },
        {
          icon: "",
          label: "GST",
          src: "GST",
        },
        {
          icon: "",
          label: "Platform Report",
          src: "platform-report",
        },
        // {
        //     icon: '',
        //     label: "Time Out Report",
        //     src: 'revenue/time-out-report'
        // }
      ],
    },
    // Master menu with expandable submenus
    {
      icon: <LeaderboardIcon />,
      label: "Master",
      hasMoreMenu: true,
      isExpanded: false,
      id: "master",
      value: "master",
      expandArray: [
        {
          icon: "",
          label: "Avatars",
          src: "avatars",
        },
        {
          icon: "",
          label: "Game Category",
          src: "games/category",
        },
        {
          icon: "",
          label: "Meta Tag",
          src: "meta-tag",
        },
        // {
        //     icon: '',
        //     label: "Promotion Ads List",
        //     src: "ads-list",
        // },
        {
          icon: "",
          label: "Documentation",
          src: "documentation",
        },
        {
          icon: "",
          label: "Lobby Type",
          src: "lobby-label",
        },
        // {
        //     icon: '',
        //     label: "Levels",
        //     src: "levels",
        // },
        {
          icon: "",
          label: "MGP Online Players",
          src: "online-players",
        },
        {
          icon: "",
          label: "Game Mode Design",
          src: "gameModeDesign",
        },
        {
          icon: "",
          label: "Splash Screen",
          src: "splash-screen",
        },
        // {
        //     icon: '',
        //     label: 'Report Reason',
        //     src: 'report-reason'
        // },
        // {
        //     icon: '',
        //     label: "Notification",
        //     src: "notification",
        // },
      ],
    },
    // Marketing menu with expandable submenus
    {
      icon: <TrendingUpIcon />,
      label: "Marketing",
      hasMoreMenu: true,
      isExpanded: false,
      id: "marketing",
      value: "marketing",
      expandArray: [
        {
          icon: "",
          label: "Promotion Ads List",
          src: "ads-list",
        },
        {
          icon: "",
          label: "Notification",
          src: "notification",
        },
        {
          icon: "",
          label: "Offer",
          src: "offer",
        },
      ],
    },
    // Design menu with expandable submenus
    // {
    //   icon: <ViewModuleIcon />,
    //   label: "Design",
    //   hasMoreMenu: true,
    //   isExpanded: false,
    //   id: "design",
    //   value: "design",
    //   expandArray: [
    //     {
    //       icon: "",
    //       label: "Login screen",
    //       src: "LoginScreen",
    //     },
    //     {
    //       icon: "",
    //       label: "Home Screen",
    //       src: "Homescreen",
    //     },
    //     {
    //       icon: "",
    //       label: "Refer and Earn",
    //       src: "referAndEarn",
    //     },
    //     {
    //       icon: "",
    //       label: "Payment Settings",
    //       src: "PaymentSettings",
    //     },
    //     {
    //       icon: "",
    //       label: "KYC Screen",
    //       src: "KYCScreen",
    //     },
    //     {
    //       icon: "",
    //       label: "Wallet Screen",
    //       src: "WalletScreen",
    //     },
    //   ],
    // },
    // {
    //     icon: <WebAssetIcon />,
    //     label: "Website",
    //     src: "website",
    //     value: 'webSite',
    //     hasMoreMenu: false,
    // },
    // Settings menu with expandable submenus
    {
      icon: <SettingIcon />,
      label: "Settings",
      hasMoreMenu: true,
      isExpanded: false,
      id: "settings",
      value: "setting",
      expandArray: [
        // {
        //     icon: '',
        //     label: "Restrict Geo",
        //     src: "setting/restrict-geo",
        // },
        {
          icon: "",
          label: "Flag Config",
          src: "setting/flag-config",
        },
        {
          icon: "",
          label: "Global Config",
          src: "setting/config",
        },
        {
          icon: "",
          label: "Env Config",
          src: "setting/env-config",
        },
        {
          icon: "",
          label: "Maintenance",
          src: "setting/maintenance",
        },
        {
          icon: "",
          label: "Restrict Geo",
          src: "setting/restrict-geo",
        },
      ],
      // expandArray: [
      //     {
      //         icon: '',
      //         label: "Restrict Geo",
      //         src: "setting/restrict-geo",
      //     },
      //     // {
      //     //     icon: '',
      //     //     label: "Payment Method",
      //     //     src: "setting/users-payment-method",
      //     // },
      //     {
      //         icon: '',
      //         label: "Flag Config",
      //         src: "setting/flag-config",
      //     },
      //     {
      //         icon: '',
      //         label: "Config",
      //         src: "setting/config",
      //     },
      //
      // ]
    },
    // MGP Release menu
    {
      icon: <SDKIcon />,
      label: "MGP Release",
      src: "release",
      value: "mgpRelease",
      hasMoreMenu: false,
    },
    // Withdrawal menu with expandable submenus
    {
      icon: <AccountBalanceIcon />,
      label: "Deposit/Withdrawal",
      hasMoreMenu: true,
      isExpanded: false,
      id: "withdrawal",
      value: "withdrawal",
      expandArray: [
        {
          icon: "",
          label: "Add Bank and UPI",
          src: "bank-and-upi",
        },
        {
          icon: "",
          label: "Add UPI QR Code",
          src: "upi-qr-code",
        },
        {
          icon: "",
          label: "Withdrawal Processing Fee",
          src: "withdrawalProcessingFee",
        },
        {
          icon: "",
          label: "User Withdrawal Request",
          src: "user-withdrawal-requests",
        },
        {
          icon: "",
          label: "User Deposit Request",
          src: "user-deposit-request",
        },
        // {
        //     icon: '',
        //     label: "Withdrawal Manually",
        //     src: "withdrawal-manually",
        // },
        {
          icon: "",
          label: "Payment Gateway",
          src: "payment-gateway",
        },
      ],
    },
    //GST
    {
      icon: <SDKIcon />,
      label: "GST",
      hasMoreMenu: true,
      isExpanded: false,
      id: "gst",
      value: "gstReport",
      expandArray: [
        {
          icon: "",
          label: "GST Report",
          src: "gst-report",
        },
        {
          icon: "",
          label: "GST Filing",
          src: "gst-filing",
        },
      ],
    },
    // {
    //     icon: <WebAssetIcon />,
    //     label: "Website",
    //     src: "website",
    //     value: 'webSite',
    //     hasMoreMenu: false,
    // },
    // TDS menu with expandable submenus
    {
      icon: <AnalyticsIcon />,
      label: "TDS",
      hasMoreMenu: true,
      isExpanded: false,
      id: "tds",
      value: "tdsReport",
      expandArray: [
        {
          icon: "",
          label: "TDS Report",
          src: "tds-report",
        },
        // {
        //     icon: '',
        //     label: "TDS Filing",
        //     src: "tds-filing",
        // }
      ],
    },
    // Help & Support menu
    {
      icon: <HelpCenterIcon />,
      label: "Help & Support",
      src: "help-and-support",
      hasMoreMenu: false,
      value: "helpAndSupport",
      routeKey: "help-and-support",
    },
    //Company Logo menu
    {
      icon: <SDKIcon />,
      label: "Company Logo",
      src: "company-logo",
      hasMoreMenu: false,
      value: "companyLogo",
      routeKey: "company-logo",
    },
    // {
    //     icon: <HistoryIcon />,
    //     label: "History",
    //     src: "history",
    //     hasMoreMenu: false,
    //     value: 'history',
    //     routeKey: 'history',
    // },
  ]);

  const { role: userRole, email } = userDetails();

  useEffect(() => {
    dispatch(getFlagConfig({}));
  }, [menusList]);

  // useEffect(()=>{
  //     if(JSON.parse(localStorage.getItem('userdata'))?.role === 'AdminUser'){
  //         let temp = [...menusList]
  //         setMenuList([ {
  //             icon: <UserIcon />,
  //             label: "Sub Admin Users",
  //             src: "sub-adminUser",
  //             value: 'subAdminUser',
  //             hasMoreMenu: false,
  //         }, ...temp])
  //     }
  // },[])

  const handleClicked = (e) => {
    navigate(`/${e}`);
  };

  const ToggleMenu = (Id) => {
    menusList.filter((item) => item.id === Id)[0].isExpanded =
      !menusList.filter((item) => item.id === Id)[0].isExpanded;
    forceUpdate();
  };

  const handleSidebar = (menu) => {
    if (
      !settingFlag?.isGameLeaderboardBonus &&
      !settingFlag?.isMobileNumberUpdateRequest
    ) {
      return menu?.expandArray
        ?.filter(
          (item) =>
            !item?.label?.includes("Users Mobile Number Request") &&
            !item?.label?.includes("Leaderboard")
        )
        ?.map((list, i) => {
          return (
            list && (
              <Collapse
                in={menu?.isExpanded}
                timeout="auto"
                unmountOnExit
                key={i}
                className={
                  (window.location.pathname.includes("/users-tab") &&
                    list?.label === "All Users") ||
                  (window.location.pathname.includes("/game-tab") &&
                    list?.label === "All Games")
                    ? "inner_list_details activeClass"
                    : "inner_list_details"
                }
              >
                <List
                  component="div"
                  disablePadding
                  onClick={() => handleClicked(list?.src)}
                >
                  <ListItemButton
                    className="list_item"
                    selected={
                      list?.src === window.location.pathname.replace("/", "")
                    }
                  >
                    {list?.icon && <ListItemIcon>{list?.icon}</ListItemIcon>}
                    <ListItemText
                      primary={list?.label}
                      className="menu_label fontFamily"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            )
          );
        });
    }
    if (!settingFlag?.isGameLeaderboardBonus) {
      return menu?.expandArray
        ?.filter((item) => item?.label !== "Leaderboard")
        ?.map((list, i) => {
          //settingFlag?.isMobileNumberUpdateRequest
          return (
            list && (
              <Collapse
                in={menu?.isExpanded}
                timeout="auto"
                unmountOnExit
                key={i}
                className={
                  (window.location.pathname.includes("/users-tab") &&
                    list?.label === "All Users") ||
                  (window.location.pathname.includes("/game-tab") &&
                    list?.label === "All Games")
                    ? "inner_list_details activeClass"
                    : "inner_list_details"
                }
              >
                <List
                  component="div"
                  disablePadding
                  onClick={() => handleClicked(list?.src)}
                >
                  <ListItemButton
                    className="list_item"
                    selected={
                      list?.src === window.location.pathname.replace("/", "")
                    }
                  >
                    {list?.icon && <ListItemIcon>{list?.icon}</ListItemIcon>}
                    <ListItemText
                      primary={list?.label}
                      className="menu_label fontFamily"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            )
          );
        });
    }
    if (!settingFlag?.isMobileNumberUpdateRequest) {
      return menu?.expandArray
        ?.filter((item) => item?.label !== "Users Mobile Number Request")
        ?.map((list, i) => {
          //settingFlag?.isMobileNumberUpdateRequest
          return (
            list && (
              <Collapse
                in={menu?.isExpanded}
                timeout="auto"
                unmountOnExit
                key={i}
                className={
                  (window.location.pathname.includes("/users-tab") &&
                    list?.label === "All Users") ||
                  (window.location.pathname.includes("/game-tab") &&
                    list?.label === "All Games")
                    ? "inner_list_details activeClass"
                    : "inner_list_details"
                }
              >
                <List
                  component="div"
                  disablePadding
                  onClick={() => handleClicked(list?.src)}
                >
                  <ListItemButton
                    className="list_item"
                    selected={
                      list?.src === window.location.pathname.replace("/", "")
                    }
                  >
                    {list?.icon && <ListItemIcon>{list?.icon}</ListItemIcon>}
                    <ListItemText
                      primary={list?.label}
                      className="menu_label fontFamily"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            )
          );
        });
    }

    if (
      settingFlag?.isGameLeaderboardBonus &&
      settingFlag?.isMobileNumberUpdateRequest
    ) {
      return menu?.expandArray?.map((list, i) => {
        //settingFlag?.isMobileNumberUpdateRequest
        return (
          list && (
            <Collapse
              in={menu?.isExpanded}
              timeout="auto"
              unmountOnExit
              key={i}
              className={
                (window.location.pathname.includes("/users-tab") &&
                  list?.label === "All Users") ||
                (window.location.pathname.includes("/game-tab") &&
                  list?.label === "All Games")
                  ? "inner_list_details activeClass"
                  : "inner_list_details"
              }
            >
              <List
                component="div"
                disablePadding
                onClick={() => handleClicked(list?.src)}
              >
                <ListItemButton
                  className="list_item"
                  selected={
                    list?.src === window.location.pathname.replace("/", "")
                  }
                >
                  {list?.icon ? (
                    <ListItemIcon>{list?.icon}</ListItemIcon>
                  ) : (
                    <ListItemIcon
                      sx={{
                        minWidth: "20px",
                        marginRight: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <PanoramaFishEyeIcon
                        sx={{ color: "#97a6ba", fontSize: "10px" }}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={list?.label}
                    className="menu_label fontFamily"
                  />
                </ListItemButton>
              </List>
            </Collapse>
          )
        );
      });
    }
  };
  useEffect(() => {
    dispatch(getPermissionsKey()).then((res) => {
      setPermotions(res.data.data.agentData);
    });
  }, []);
  return (
    <div className="gp_left-bar">
      <div className="side_logo_box">
        <Link className="side_logo d_flex align_items_center" to="/dashboard">
          <img src={Logo} alt={"Logo"} className={"Logo"} />
          <Box sx={{ width: "100%", marginTop: "10px" }}>
            <Typography
              sx={{
                fontSize: "36px",
                lineHeight: "1.5rem",
                fontWeight: 600,
                fontFamily: "sans-serif",
              }}
            >
              BIGWIN
            </Typography>
            <Typography
              sx={{
                letterSpacing: "7px",
                lineHeight: "1.5rem",
                marginLeft: "3px",
                fontWeight: 500,
                fontFamily: "sans-serif",
              }}
            >
              JUNCTION
            </Typography>
          </Box>
        </Link>
      </div>
      <List
        sx={{ width: "100%", maxWidth: 360, padding: "0" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={"tab_sidebar_details"}
      >
        {menusList?.map((menu, i) => {
          //let agentDataDetails = JSON.parse(localStorage.getItem('agentData')) ||
          //let agentDataDetails = cookies.get('agentData');
          let agentDataDetails = agentDetails();
          let payload = {
            permission: {
              ...agentDataDetails.permission,
              subAdminUser: {
                editor:
                  JSON.parse(localStorage.getItem("userdata"))?.role ===
                  "AdminUser",
                viewer:
                  JSON.parse(localStorage.getItem("userdata"))?.role ===
                  "AdminUser",
              },
            },
          };
          let AgentCondition =
            agentDataDetails !== "null" &&
            payload &&
            menu.value ===
              Object?.keys(payload?.permission || {})[
                Object?.keys(payload?.permission || {})?.indexOf(menu.value)
              ];
          let agentValueViewer =
            Object?.keys(payload?.permission || {})?.length > 0 &&
            payload?.permission[
              Object?.keys(payload?.permission || {})[
                Object?.keys(payload?.permission || {})?.indexOf(menu.value)
              ]
            ]?.viewer;
          let agentValueEditor =
            Object?.keys(payload?.permission || {})?.length > 0 &&
            payload?.permission[
              Object?.keys(payload?.permission || {})[
                Object?.keys(payload?.permission || {})?.indexOf(menu.value)
              ]
            ]?.editor;
          let agentValue = agentValueViewer || agentValueEditor;
          console.log(menu);

          return (
            <React.Fragment key={i}>
              {/* Check if user has permission to view the menu */}

              {(userRole === "Admin" || (AgentCondition && agentValue)) && (
                <>
                  <ListItemButton
                    sx={{ padding: "5px 15px" }}
                    selected={
                      menu?.src === window.location.pathname.replace("/", "")
                    }
                    className="list_item fontFamily"
                    onClick={() =>
                      menu.hasMoreMenu
                        ? ToggleMenu(menu?.id)
                        : handleClicked(menu?.src)
                    }
                  >
                    <ListItemIcon
                      style={{ minWidth: "20px" }}
                      className={"icon-left-side fontFamily"}
                    >
                      {menu?.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu?.label}
                      className="menu_label fontFamily"
                    />
                    {menu?.hasMoreMenu ? (
                      menu?.isExpanded ? (
                        <ExpandMore sx={{ fontSize: "20px" }} />
                      ) : (
                        <ChevronRightIcon sx={{ fontSize: "20px" }} />
                      )
                    ) : (
                      ""
                    )}
                  </ListItemButton>

                  {menu?.expandArray && handleSidebar(menu)}
                </>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );
}

export default LeftContent;
